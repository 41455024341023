.label {
	display: inline-block;
	margin-bottom: 15px;
	margin-left: -10px;
	padding: 5px 10px;
	font-family: var(--font-heading);
	font-size: 16px;
	color: #fff;
	background-color: var(--color2);
	transform: rotate(-2deg);
}

.label--mb0 {
	margin-bottom: 0;
}
