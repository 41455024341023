.header {
	--pad: 25px;
	padding: var(--pad);
}

@media (min-width: 1024px) {
	.header {
		--pad: 50px;
	}
}

@media (min-width: 1280px) {
	.header {
		--pad: 50px 100px;
	}
}


@media (max-width: 1023px) {
	.main > .header > .img {
		margin-top: 15px;
	}
}

@media (min-width: 1024px) {
	.main > .header {
		display: flex;
		padding-top: 25px;
		padding-right: 25px;
		padding-bottom: 0;
	}

		.main > .header > * {
			flex: 0 0 50%;
		}

		.main > .header > .text {
			padding-right: 25px;
		}


	.person .main > .header {
		align-items: flex-end;
	}
}


.section .header + * {
	padding-top: 0 !important;
}
