.fonts-loaded {
	--font-body: "Open Sans";
	--font-heading: Rubik;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 15px;
	font-family: var(--font-heading);
	color: inherit;
}

h1:last-child, h2:last-child, h3:last-child {
	margin-bottom: 0;
}


h1 {
	font-size: calc(16px + 1.5vw);
}

h2 {
	font-size: calc(16px + 1vw);
}

h3 {
	font-size: calc(16px + .5vw);
}

h4 {
	font-size: calc(16px + .25vw);
}

h5 {
	font-size: 18px;
}

.subheading {
	color: var(--color2);
}

p {
	margin-bottom: 20px;
}

	p:last-child {
		margin-bottom: 0;
	}


.text {
	margin-bottom: 25px;
	font-size: 16px;
	font-style: normal;
	color: inherit;
}

	.text--mb0 {
		margin-bottom: 0;
	}

	.text--small {
		font-size: 12px;
		line-height: 1.5;
	}

	.text--strong {
		font-weight: 700;
	}

	.text--white {
		color: #fff;
	}

	.text--emphasis {
		color: var(--color2);
	}


@media (max-width: 999px) {
	br {
		display: none;
	}

	.address br {
		display: block;
	}
}


hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	margin-top: 25px;
	margin-bottom: 25px;
	border: 0;
	border-top: 10px solid var(--green-lighter);
}


ul.list {
	list-style: none; /* Remove default bullets */
	margin-bottom: 20px;
	padding-left: 1.25em;
}

ul.list:last-child {
	margin-bottom: 0;
}

	ul.list li {
		margin-bottom: 5px;
	}

	ul.list li:last-child {
		margin-bottom: 0;
	}

	ul.list li::before {
		content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
		display: inline-block;
		width: 1rem;
		margin-left: -1rem;
		font-weight: bold;
		color: var(--color2);
	}


ol.list {
	margin-bottom: 20px;
	padding-left: 1.25em;
}

ol.list:last-child {
	margin-bottom: 0;
}
