button {
	overflow: visible;
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5;
	text-transform: none;
	cursor: pointer;
	background: transparent;
	border: 0;
	border-radius: 0;
	-webkit-appearance: button;
}

button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}


[class^="button"] {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 10px 25px;
	font-family: inherit;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: #fff;
	background: var(--pink);
	transition: var(--transition);
	border: 1px solid var(--accent);
	border-radius: 100px;
}

	[class^="button"]:focus,
	[class^="button"]:hover {
		color: #fff;
		background-color: var(--blue);
		border-color: var(--blue);
	}

	[class^="button"]:disabled,
	[class^="button"][class*="disabled"] {
		pointer-events: none;
		opacity: .66;
	}


	/* a11y */
	[class^="button"]:active,
	[class^="button"]:focus {
		transition: none;
		outline: 2px dotted var(--black);
		outline-offset: 2px;
	}


[class^="button"][class*="--block"] {
	display: block;
	width: 100%;
}
