@media (min-width: 768px) {
	.sitemap {
		display: grid;
		grid-template-columns: 250px repeat(auto-fill, minmax(200px, 1fr));
		grid-gap: 25px;
	}
}

.sitemap h3 {
	margin: 0 0 15px;
	font-size: 14px;
	color: #fff;
}

	.sitemap-group {
		margin-top: 30px;
		font-family: var(--font-heading);
	}

		.sitemap-group li a {
			position: relative;
			display: inline-block;
			padding: 5px 0;
			color: #fff;
		}

		.sitemap-group li a::after {
			content: "";
			position: absolute;
			top: 80%;
			display: block;
			width: 100%;
			height: 1px;
			background: var(--accent);
			transition: var(--transition);
		}

		.sitemap-group li a:focus::after,
		.sitemap-group li a:hover::after {
			height: 2px;
		}


.sitemap .address {
	margin-top: 30px;
}
