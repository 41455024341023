.link {
	font-weight: 700;
	color: var(--accent);
	border-bottom: 2px solid transparent;
	transition: var(--transition);
}

.link:focus,
.link:hover {
	border-color: var(--color1);
}


figcaption .link {
	font-weight: normal;
}


/* iOS telephone number detection */
a[href^=tel] {
	color: var(--accent);
}


.arrow-link {
	position: relative;
	display: inline-block;
	padding-right: 25px;
	font-family: var(--font-heading);
	color: var(--accent);
	transition: var(--transition);
}

	.arrow-link::after {
		content: "";
		position: absolute;
		top: 1px;
    	right: 0;
		width: 25px;
		height: 25px;
		background-image: url("../../svg/arrow-right.svg");
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 18px;
		transition: var(--transition);
	}

	.arrow-link:focus::after,
	.arrow-link:hover::after {
		background-image: url("../../svg/arrow-right-blue.svg");
	}

	.arrow-link + .arrow-link {
		margin-left: 50px;
	}


.heading-link {
	color: var(--accent);
}


.media-link {
	font-family: var(--font-heading);
	color: var(--pink);
}

.media-link::before{
	content: url("../../svg/download.svg");
	position: relative;
	display: inline-block;
	top: 3px;
	width: 16px;
	height: 16px;
	padding-right: 10px;
	transition: var(--transition);
}

.media-link:focus::before,
.media-link:hover::before {
	content: url("../../svg/download-blue.svg");
}
