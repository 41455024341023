.atabs {
	position: relative;
	margin: 0 25px;
}

@media (min-width: 1000px) {
	.atabs {
		margin: 0 50px;
	}
}

@media (min-width: 1200px) {
	.atabs {
		margin: 0 100px;
	}
}

	.atabs-list:not([aria-orientation="vertical"]) {
		display: flex;
		white-space: nowrap;
		overflow: auto;
	}

	[data-atabs-orientation="vertical"] {
		display: flex;
		flex-wrap: wrap;
	}


	.atabs-list[aria-orientation="vertical"] {
		width: 100%;
	}

	.atabs-list[aria-orientation="vertical"] .atabs-list-tab {
		display: inline-block;
		width: 100%;
		text-align: left;
	}

	[data-atabs-orientation="vertical"] .atabs-panel {
		width: 100%;
	}

	@media (min-width: 544px) {
		[data-atabs-orientation="vertical"] .atabs-panel {
			width: calc(100% - 200px);
			padding-left: 25px;
		}

		.atabs-list[aria-orientation="vertical"] {
			width: 200px;
		}
	}

	@media (min-width: 1000px) {
		[data-atabs-orientation="vertical"] .atabs-panel {
			padding-left: 50px;
		}
	}

	@media (min-width: 1200px) {
		[data-atabs-orientation="vertical"] .atabs-panel {
			width: calc(100% - 300px);
			padding-left: 150px;
		}

		.atabs-list[aria-orientation="vertical"] {
			width: 300px;
		}
	}


	.atabs-list[hidden] {
		display: none;
	}

	/**
	 * Just to make sure invalid children of a
	 * tablist are not visible/accessible.
	 * these should also be removed from the DOM
	 */
	.atabs-list > :not([role="tab"]) {
		display: none;
	}

	.atabs-list-tab {
		appearance: none; /* btn */
		flex-grow: 1;
		position: relative;
		margin: 0; /* btn */
		padding: 10px 25px;
		font: inherit; /* btn */
		font-family: var(--font-heading);
		font-weight: 700;
		text-decoration: none; /* if <a> */
		color: var(--color1);
		background: transparent;
		border: 1px solid var(--color1); /* btn */
	}

	@media (min-width: 544px) {
		.atabs-list-tab {
			padding: 10px 25px 10px 35px;
			color: var(--accent);
			border: 0;
		}

		.atabs-list-tab:hover {
			color: var(--color1);
		}
	}

	.atabs-list-tab:active,
	.atabs-list-tab:focus {
		color: #fff;
		background-color: var(--accent);
		outline: none;
		outline-offset: -2px;
	}

	.atabs-list-tab:active {
		box-shadow: inset 0 1px 0 4px var(--color1);
	}

	.atabs-list-tab > span,
	.atabs-list-tab > svg {
		pointer-events: none;
	}

	.atabs-list-tab[aria-selected="true"] {
		color: #fff;
		background-color: var(--color1);
	}

	@media (min-width: 544px) {
		.atabs-list-tab[aria-selected="true"] {
			color: var(--color1);
			background-color: transparent;
		}
	}

	@media (min-width: 544px) {
		.atabs-list-tab::before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			display: inline-block;
			margin-top: -1px;
			width: 25px;
			height: 3px;
			background-color: var(--accent);
		}

		.atabs-list-tab[aria-selected="true"]::before {
			background-color: var(--color1);
		}
	}

	.atabs-list-tab[aria-selected="true"]:hover,
	.atabs-list-tab[aria-selected="true"]:focus {
		box-shadow: inset 0 1px 0 4px #fff;
	}

	.atabs-panel[hidden] {
		display: none;
	}

	.atabs-panel {
		padding: 25px 0;
		outline: none;
	}

	@media (min-width: 1000px) {
		.atabs-panel {
			padding-top: 0;
		}
	}

		.atabs-panel > :first-child {
			margin-top: 0;
		}

		.atabs-panel > :last-child {
			margin-bottom: 0;
		}


		@media (min-width: 1000px) {
			.atabs-panel > .img {
				margin-left: 100px;
			}
		}

		.atabs-panel > .text--overlay {
			padding: 25px;
			background-color: #fff;
		}

		@media (min-width: 1000px) {
			.atabs-panel > .text--overlay {
				position: relative;
				margin-top: -75px;
				margin-right: 100px;
				padding: 50px;
			}
		}

		[class*="--waves"] .atabs-panel > .text--overlay {
			background-color: var(--bg);
		}
