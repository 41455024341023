.video {
	position: relative;
}

	.video + * {
		margin-top: 25px;
	}


		/* If the video is in a section with block padding */

		.block--padding > .video {
			margin: -25px 0;
		}

		@media (min-width: 1280px) {
			.block--padding > .video {
				margin: -50px -100px -25px;
			}
		}

		/* If the video is in a grid offset with cell padding */

		.cell--padding > .video {
			margin: -25px;
		}

		@media (min-width: 768px) {
			.cell--padding > .video {
				margin: -50px;
			}
		}

		@media (min-width: 1280px) {
			[class^="grid"][class*="--cells-2"] > .cell--padding > .video {
				margin: -50px -100px;
			}
		}
