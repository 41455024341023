.logo .ico {
	width: 160px;
	height: 60px;
}

@media (min-width: 1200px) {
	.logo .ico {
		width: 220px;
		height: 80px;
	}
}


.logo svg {
	width: 160px;
	height: 60px;
}

@media (min-width: 1200px) {
	.logo svg {
		width: 220px;
		height: 80px;
	}
}

		.logo svg g,
		.logo svg path {
			fill: var(--fill);
		}


.logo--top {
	position: relative;
	z-index: 2;
}

		.logo--top svg g {
			--fill: #00486e;
		}

		.logo--top svg g + g {
			--fill: #c91c6d;
		}

		.logo--top svg path:nth-child(3) {
			--fill: #BED730;
		}

		.logo--top svg path:nth-child(4) {
			--fill: #028543;
		}

		.logo--top svg path:nth-child(5) {
			--fill: #00AAA5;
		}

		.logo--top svg path:nth-child(6) {
			--fill: #84246f;
		}

		.logo--top svg path:nth-child(7) {
			--fill: #c91c6d;
		}

		.logo--top svg path:nth-child(8) {
			--fill: #f68832;
		}

		.logo--top svg path:nth-child(9) {
			--fill: #fddd00;
		}

		.menu-is-open .logo--top svg g,
		.menu-is-open .logo--top svg path {
			--fill: #fff;
		}


@media (max-width: 1249px) {
	.logo--bottom {
		grid-column: 1 / -1;
	}
}

		.logo--bottom svg g,
		.logo--bottom svg path {
			--fill: #fff;
		}

