@media (max-width: 767px) {
	.entry {
		padding: 25px;
	}
}

	.entry > .header .text {
		max-width: 640px;
		margin-left: auto;
		margin-right: auto;
	}

	@media (min-width: 641px) {
		.entry > .header .text {
			padding: 0 10px;
		}
	}


	.entry > .section .block {
		max-width: 640px;
		margin-left: auto;
		margin-right: auto;
	}
