@import "./stylesheets/_root.css";

@import "./stylesheets/_base.css";
@import "./stylesheets/_links.css";
@import "./stylesheets/_text.css";
@import "./stylesheets/_tables.css";
@import "./stylesheets/_images.css";
@import "./stylesheets/_buttons.css";
@import "./stylesheets/_form.css";

@import "./stylesheets/_page.css";
@import "./stylesheets/_logo.css";
@import "./stylesheets/_menu.css";
@import "./stylesheets/_header.css";
@import "./stylesheets/_section.css";
@import "./stylesheets/_grid.css";
@import "./stylesheets/_entry.css";

@import "./stylesheets/_cards.css";
@import "./stylesheets/_callout.css";
@import "./stylesheets/_label.css";
@import "./stylesheets/_tabs.css";
@import "./stylesheets/_newsletter.css";
@import "./stylesheets/_pagination.css";
@import "./stylesheets/_pull-quote.css";
@import "./stylesheets/_sitemap.css";
@import "./stylesheets/_social-networks.css";
@import "./stylesheets/_video.css";
@import "./stylesheets/_waves.css";

@import "./stylesheets/_youtube-player.css";
@import "./stylesheets/_aspect-ratio.css";
@import "./stylesheets/_utils.css";

#breakpoint::after {
	content: "xsmall";
	display: none;
}

@media only screen and (max-width: 767px) {
	#breakpoint::after {
		content: "small";
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	#breakpoint::after {
		content: "medium";
		display: none;
	}
}

@media only screen and (min-width: 1000px) {
	#breakpoint::after {
		content: "large";
		display: none;
	}
}
