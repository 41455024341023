blockquote {
	quotes: "\201C""\2018";
	margin-bottom: 50px;
	font-size: 18px;
	font-weight: 700;
}

blockquote:last-child {
	margin-bottom: 0;
}

	blockquote::before {
		content: open-quote;
		font-size: 40px;
		line-height: 5px;
		margin-right: 5px;
		vertical-align: -20px;
		color: var(--color2);
	}

	blockquote > * {
		padding-left: 25px;
	}

	blockquote > p:last-of-type {
		margin-bottom: 0;
	}

	blockquote cite {
		display: block;
		margin-top: 10px;
		font-size: 14px;
		font-style: normal;
		color: var(--color1);
	}

	blockquote cite::before {
		content: "";
		display: inline-block;
		width: 16px;
		height: 2px;
		vertical-align: middle;
		margin-top: -1px;
		margin-right: 5px;
		background-color: var(--color2);
	}
