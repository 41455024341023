.callout {
	position: relative;
	padding: 25px;
	color: #fff;
	background-color: var(--color1);
}

@media (min-width: 768px) {
	.callout {
		padding: 50px;
	}
}

.callout + .callout {
	color: var(--color1);
	background-color: var(--bg);
}

@media (min-width: 1000px) {
	.callout + .callout {
		border-top: 25px solid #fff;
		border-bottom: 25px solid #fff;
	}

	.callout:not(:only-of-type) {
		float: left;
		width: 50%;
		height: 100%;
	}
}


	.callout:first-of-type {
		color: #fff;
	}

		.callout:first-of-type .link {
			color: #fff;
			border-color: #fff;
		}


		.callout:first-of-type .media-link {
			color: #fff;
			border-color: #fff;
		}

		.callout:first-of-type .media-link::before{
			content: url("../../svg/download-white.svg");
		}

		.callout:first-of-type .media-link:focus::before,
		.callout:first-of-type .media-link:hover::before {
			content: url("../../svg/download.svg");
		}


		.callout:first-of-type .arrow-link {
			color: #fff;
		}

		.callout:first-of-type .arrow-link::after {
			background-image: url("../../svg/arrow-right-white.svg");
		}

		.callout:first-of-type .arrow-link:focus::after,
		.callout:first-of-type .arrow-link:hover::after {
			background-image: url("../../svg/arrow-right.svg");
		}
