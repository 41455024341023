.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 50px auto 0;
	background: #fff;
	cursor: default;
}

	.pagination a,
	.pagination em,
	.pagination > span {
		padding: 10px 25px;
	}

	.pagination a,
	.pagination em {
		font-style: normal;
		font-weight: 700;
		text-decoration: none;
		color: var(--accent);
	}

	@media (max-width: 767px) {
		.pagination a,
		.pagination em {
			display: none;
		}
	}

	.pagination a:focus,
	.pagination a:hover {
		color: var(--color1);
	}

	.pagination .paginate-current {
		color: var(--color1);
	}

	.pagination .paginate-next,
	.pagination .paginate-previous {
		position: relative;
		margin: 0 10px;
		text-align: center;
	}

	@media (max-width: 767px) {
		.pagination .paginate-next,
		.pagination .paginate-previous {
			display: inline-block;
			padding: 10px 25px;
			color: var(--accent);
			transition: var(--transition);
			border: 2px solid var(--accent);
			border-radius: 100px;
		}

		.pagination .paginate-next.disabled,
		.pagination .paginate-previous.disabled {
			border-color: var(--blue-light);
		}
	}

	.pagination .paginate-next.disabled,
	.pagination .paginate-previous.disabled {
		color: var(--blue-light);
	}
