table {
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	margin-bottom: 40px;
	background-color: transparent;
	border: 1px solid;
}

table th,
table td {
	padding: 15px;
	vertical-align: top;
	border: 1px solid;
}

table thead th {
	vertical-align: bottom;
	border-bottom: 2px solid;
}

table tbody + tbody {
	border-top: 2px solid;
}
