.bg {
	background-color: var(--background);
}

.db {
	display: block;
}

.mb0 {
	margin-bottom: 0;
}

.mt10 {
	margin-top: 10px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}


.full-width {
	position: relative;
	left: 50%;
	right: 50%;
	width: 100vw;
	margin-left: -50vw;
	margin-right: -50vw;
	padding-left: 10px;
	padding-right: 10px;
}


.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}


.a11y-hint,
.a11y-text {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	border: 0;
	clip: rect(0 0 0 0);
}
