
[class*="--waves"] {
	padding-top: 100px;
	padding-bottom: 100px;
	background-color: #fff;
	background-position: top center, bottom center;
	background-repeat: no-repeat, no-repeat;
}

.section--waves-1 {
	background-image: url("../../svg/waves-1-down.svg"), url("../../svg/waves-1-up.svg");
}

.section--waves-2 {
	background-image: url("../../svg/waves-2-down.svg"), url("../../svg/waves-2-up.svg");
}

.grid--waves,
.posts--waves {
	background-image: url("../../svg/waves-2-down.svg"), url("../../svg/waves-2-up.svg");
}
