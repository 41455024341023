.social-networks {
	margin-top: 30px;
}

	.social-networks a {
		display: inline-block;
		width: 38px;
		height: 38px;
	}

	.social-networks a:first-child {
		margin-left: -10px;
	}

	.social-networks a:not(:last-child) {
		margin-right: 10px;
	}

		.social-networks a svg {
			width: 38px;
			height: 38px;
			vertical-align: middle;
		}

			.social-networks a svg path {
				fill: #fff;
			}

			.social-networks a svg circle {
				stroke: #fff;
			}

			.social-networks a svg circle.animate {
				stroke: var(--accent);
				stroke-width: 2px;
				stroke-dasharray: 227;
				stroke-dashoffset: 227;
				transition: var(--transition);
			}

			.social-networks a:focus svg circle.animate,
			.social-networks a:hover svg circle.animate {
				stroke-dashoffset: 0;
			}

