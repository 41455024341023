:root {
	--font-body: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Helvetica Neue", Arial, sans-serif;
	--font-heading: var(--font-body);

	--pink: #c91c6d;
	--pink-dark: #ac0f58;

	--green: #009a96;
	--green-light: #00aaa5;
	--green-lighter: #eaf8f7;
	--green-dark: #0a8481;

	--blue: #00486e;
	--blue-light: #75a4b8;
	--blue-lighter: #bad3dc;
	--blue-lightest: #e3edf2;

	--grey: #d8d8d8;
	--grey-dark: #8e979c;

	--color1: var(--blue);
	--color2: var(--green);

	--accent: var(--pink);

	--bg: #f6fAfc;

	--borderColor: var(--blue-lightest);

	--transition: all .6s cubic-bezier(.54,.1,0,.99);
	--menu-transition: all .2s ease-in-out;
}
