.site-menu {
	display: none;
}

@media (min-width: 1024px) {
	.site-menu {
		display: flex;
		margin: 10px 0 10px auto;
	}
}

@media (min-width: 1200px) {
	.site-menu {
		margin: 18px 0 25px auto;
	}
}

	.site-menu a {
		position: relative;
		display: inline-block;
		padding: 10px 0;
		font-size: 14px;
		font-weight: 700;
		color: var(--color1);
	}

		.site-menu a::after {
			content: "";
			position: absolute;
			top: 80%;
			display: block;
			width: 100%;
			height: 2px;
			background: transparent;
			transition: var(--transition);
		}

		.site-menu a:focus::after,
		.site-menu a:hover::after,
		.site-menu [aria-current="page"]::after {
			background: var(--accent);
		}

		.site-menu [aria-current="page"]::after {
			background: var(--color1);
		}

	.site-menu a + a {
		margin-left: 25px;
	}

	@media (min-width: 1200px) {
		.site-menu a + a {
			margin-left: 50px;
		}
	}


.supporting-menu {
	display: flex;
	padding: 0 25px;
	background: var(--accent);
}

@media (max-width: 1023px) {
	.supporting-menu {
		width: 100%;
		height: 44px;
		justify-content: center;
	}
}

@media (min-width: 1024px) {
	.supporting-menu {
		margin: -10px -15px -10px 15px;
	}
}

	.supporting-menu a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 16px;
		font-weight: bold;
		color: #fff;
	}

	@media (min-width: 1200px) {
		.supporting-menu a {
			font-size: 16px;
		}
	}

	.supporting-menu a span {
		position: relative;
		display: inline-block;
		padding: 5px 0;
	}

	@media (min-width: 1024px) {
		.supporting-menu a span {
			margin-top: -5px;
		}
	}

	@media (min-width: 1200px) {
		.supporting-menu a span {
			margin-top: -10px;
		}
	}

	@media (min-width: 1024px) {
		.supporting-menu a span:after {
			content: "";
			position: absolute;
			top: 100%;
			display: block;
			width: 100%;
			height: 2px;
			background: var(--pink-dark);
			transition: var(--transition);
		}

		.supporting-menu a:focus span:after,
		.supporting-menu a:hover span:after {
			background: #fff;
		}
	}


.menu-button {
	display: none;
}

.menu-button.enhanced {
	position: relative;
	z-index: 2;
	display: block;
	margin: 7px 0 7px auto;
	padding: 15px;
	background: transparent;
	border: 0;
}

@media (min-width: 1024px) {
	.menu-button.enhanced {
		display: none;
	}
}

	/* a11y */
	.menu-button.enhanced:active,
	.menu-button.enhanced:focus {
		outline: 2px dotted var(--accent);
		outline-offset: 1px;
	}

	.menu-button.enhanced .burger {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 20px;
		height: 20px;
		cursor: pointer;
		transition: transform 330ms ease-out;
	}

	.menu-button.enhanced[aria-expanded="true"] .burger {
		transform: rotate(-45deg);
	}

		.menu-button.enhanced .line {
			width: 100%;
			height: 2px;
			background-color: var(--accent);
		}

		.menu-button.enhanced .line01,
		.menu-button.enhanced .line03 {
			width: 50%;
		}

		.menu-button.enhanced .line01 {
			align-self: flex-end;
			transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
			transform-origin: left;
		}

		.menu-button.enhanced[aria-expanded="true"] .line {
			background-color: #fff;
		}

		.menu-button.enhanced[aria-expanded="true"] .line01 {
			transform: rotate(-90deg) translateX(-9px);
		}

		.menu-button.enhanced .line03 {
			transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
			transform-origin: right;
		}

		.menu-button.enhanced[aria-expanded="true"] .line03 {
			transform: rotate(-90deg) translateX(9px);
		}

		@media (prefers-reduced-motion: reduce) {
			.menu-button.enhanced .burger,
			.menu-button.enhanced .line01,
			.menu-button.enhanced .line03 {
				transition: none;
			}
		}


.menu {
	display: none;
}

.enhanced .menu {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	right: 0;
	display: block;
	background-color: var(--color1);
	transition: var(--menu-transition);
	overflow-y: auto;
    -webkit-overflow-scrolling: touch;
	pointer-events: none;
	opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
	.enhanced .menu {
		--menu-transition: none;
	}
}

.enhanced .menu--open {
	pointer-events: auto;
	opacity: 1;
}

	.menu > ul {
		width: 100%;
		padding: 100px 25px 50px;
	}

	.menu > ul ~ h3 {
		padding: 0 25px;
		text-align: right;
		color: #fff;
	}

	.menu > ul ~ h3 ~ ul {
		padding-top: 0;
		text-align: right;
	}

		.menu > ul > li {
			padding: 10px 0;
		}

			.menu > ul > li > a {
				display: block;
				font-size: calc(20px + .2vw);
				color: #fff;
				transition: var(--transition);
			}

			.menu > ul > li > a:focus,
			.menu > ul > li > a:hover,
			.menu > ul > li > [aria-current="page"] {
				font-weight: 700;
			}
