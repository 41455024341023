
[class^="grid"][class*="--cells"] {
	display: flex;
	flex-flow: row wrap;
	padding: 0 25px;
}

	[class^="grid"][class*="--cells"] > * {
		flex: 0 0 auto;
		width: 100%;
	}

	@media (min-width: 768px) {
		[class^="grid"][class*="--cells-2"] > *,
		[class^="grid"][class*="--cells-4"] > * {
			width: 50%;
		}
	}

	@media (min-width: 1024px) {
		[class^="grid"][class*="--cells-4"] > * {
			width: 25%;
		}
	}

		.cell--padding {
			padding: 25px 0;
		}

		@media (min-width: 768px) {
			.cell--padding {
				padding: 50px;
			}
		}

		/* Tweak cell padding for 2 cells arrangement only */

		@media (min-width: 1280px) {
			[class^="grid"][class*="--cells-2"] > .cell--padding {
				padding: 50px 100px;
			}
		}


[class^="grid"].center {
	align-items: center;
}


@media (min-width: 768px) {
	[class^="grid"].reverse {
		flex-direction: row-reverse;
	}
}


[class^="grid"].overlay-cell > .cell--padding {
	padding: 25px;
	background-color: #fff;
}

@media (min-width: 1024px) {
	[class^="grid"].overlay-cell > .cell--padding {
		padding: 50px;
	}
}

@media (min-width: 1200px) {
	[class^="grid"].overlay-cell > .cell--padding {
		position: relative;
		z-index: 1;
	}

	[class^="grid"].overlay-cell > :first-child.cell--padding,
	[class^="grid"].overlay-cell.reverse > :last-child.cell--padding {
		margin-right: -50px;
	}

	[class^="grid"].overlay-cell > :last-child.cell--padding {
		margin-left: -50px;
	}
}


[class^="grid"][class*="--waves"] .overlay-cell > .cell--padding {
	background-color: var(--bg);
}
