
.newsletter-link {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	padding: 25px;
	background-color: var(--pink);
}

.newsletter-link:focus,
.newsletter-link:hover {
	background-color: var(--pink-dark);
}

@media (min-width: 1000px) {
	.newsletter-link {
		padding: 25px 50px;
	}
}

	.newsletter-link .label {
		color: var(--pink);
		background-color: #fff;
	}

	@media (min-width: 1100px) {
		.newsletter-link .label,
		.newsletter-link .text {
			margin-bottom: 0;
		}
	}

	@media (min-width: 960px) {
		.newsletter-link .text {
			padding: 0 15px;
		}
	}

	.newsletter-link .action {
		position: relative;
		display: inline-block;
		padding-right: 25px;
		font-weight: 700;
		color: #fff;
	}

		.newsletter-link .action::after {
			content: "";
			position: absolute;
			top: -1px;
			right: 0;
			width: 25px;
			height: 25px;
			background-image: url("../../svg/arrow-right-white.svg");
			background-position: center center;
			background-repeat: no-repeat;
			background-size: 18px;
			transition: var(--transition);
		}


.newsletter-form .text {
	margin-left: 15px;
}
