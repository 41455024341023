[class^="blocks"] {
	display: flex;
	flex-flow: row wrap;
	padding: 25px 0;
}

	[class^="blocks"] > * {
		flex: 0 0 auto;
		width: 100%;
	}

	[class^="blocks"] + [class^="blocks"] {
		padding-top: 0;
	}

		.block {
			padding-left: 25px;
			padding-right: 25px;
			padding-bottom: 25px;
		}

		@media(min-width: 1024px) {
			.block {
				padding-left: 50px;
				padding-right: 50px;
				padding-bottom: 50px;
			}
		}

		@media (min-width: 1280px) {
			.block--padding {
				padding-top: 50px;
				padding-left: 100px;
				padding-right: 100px;
			}
		}

		@media (min-width: 768px) {
			.blocks-2 > *,
			.blocks-3 > *,
			.blocks-4 > * {
				width: 50%;
			}
		}

		@media (min-width: 768px) and (max-width: 1023px) {
			.blocks-3 {
				justify-content: center;
			}
		}

		@media (min-width: 1024px) {
			.blocks-3 > * {
				width: 33.3333%;
			}
		}

		@media (min-width: 1280px) {
			.blocks-3 .block--padding.block--1 {
				padding-right: 0;
			}

			.blocks-3 .block--padding.block--2 {
				padding-left: 50px;
				padding-right: 50px;
			}

			.blocks-3 .block--padding.block--3 {
				padding-left: 0;
			}
		}


.section > .footer {
	padding: 25px;
}
