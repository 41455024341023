.cards {
	padding: 0 25px 50px;
}


@supports (display: grid) {
	[class^="cards"][class*="auto-fit"] > ol,
	[class^="cards"][class*="auto-fit"] > ul {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}


	[class^="cards"][class*="auto-fill"] > ol,
	[class^="cards"][class*="auto-fill"] > ul {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
		grid-column-gap: 25px;
		grid-row-gap: 25px;
	}

	@media only screen and (min-width: 1440px) {
		[class^="cards"][class*="auto-fit"] > ol,
		[class^="cards"][class*="auto-fit"] > ul,
		[class^="cards"][class*="auto-fill"] > ol,
		[class^="cards"][class*="auto-fill"] > ul {
			grid-column-gap: 50px;
			grid-row-gap: 50px;
		}
	}
}


.card {
	position: relative;
	display: flex;
	flex-direction: column;
	word-wrap: break-word;
	background-clip: border-box;
	border: 1px solid var(--borderColor);
	transition: .25s all;
}

.card:only-child {
	max-width: 300px;
}

.card--link:hover,
.card--link:focus-within {
	box-shadow: 0 0 0 0.25rem var(--color1);
}

	.card + .card {
		margin-top: 25px;
	}

	@supports (display: grid) {
		.card + .card {
			margin-top: 0;
		}
	}

	/**
	 * pseudo heading link cover.
	 */
	 .card h3 a::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	/**
	 * Enable `flex-grow: 1` for decks and groups so that card blocks take up
	 * as much space as possible, ensuring footers are aligned to the bottom.
	 */
	.card-text {
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
		padding: 25px;
		background-color: #fff;
	}

		.card-text p:not(:last-child) {
			margin-bottom: 15px;
		}

		.card-text .card-meta {
			margin-top: auto;
		}

			.card-text .card-meta a {
				position: relative;
			}


@media (min-width: 1200px) {
	.home .card:nth-child(2) {
		margin-top: 25px;
		margin-bottom: -25px;
	}

	.home .card:nth-child(3) {
		margin-top: 50px;
		margin-bottom: -50px;
	}
}


.card--person .card-img {
	padding: 50px;
}

	.card--person .card-img > * {
		overflow: hidden;
		border: 5px solid #fff;
		box-shadow: 0 0 0 1px var(--borderColor);
		border-radius: 100%;
	}
