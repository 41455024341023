label {
	font-size: 12px;
	padding-left: 15px;
}

input,
select,
textarea {
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5;
	border-radius: 0;
	appearance: none;
}

input,
textarea {
	display: inline-block;
	width: 100%;
	margin: 0;
	padding: 10px 25px;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid var(--accent);
	border-radius: 50px;
	transition: border-color .15s ease-in-out;
	outline: 0;
}

select {
	display: inline-block;
	width: 100%;
	height: 46px;
	padding: 10px 25px;
	color: var(--accent);
	vertical-align: middle;
	background: url("../../svg/arrow-down.svg") no-repeat right 25px center/15px 15px;
	background-color: #fff;
	border: 1px solid var(--accent);
	border-radius: 50px;
	transition: border-color .15s ease-in-out;
	outline: 0;
}

input:focus,
select:focus,
textarea:focus {
	box-shadow: 0 0 0 2px var(--accent);
	outline: 0;
}

::placeholder {
	color: var(--color2);
}

:focus::placeholder {
	color: transparent;
}


.field-group {
	margin-bottom: 25px;
}


[class*="-selection"] select {
	display: inline-block;
	width: 100%;
	padding: 10px 15px;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.5;
	vertical-align: middle;
	color: var(--accent);
	background: #fff url("../../svg/arrow-down.svg") no-repeat right 15px center/15px 25px;
	background-clip: padding-box;
	transition: var(--transition);
	border: 1px solid var(--accent);
	border-radius: 100px;
}

@media (min-width: 1200px) {
	[class*="-selection"]  select {
		font-size: 14px;
	}
}

[class*="-selection"]  select:focus {
	outline: 0;
}


.network-selection {
	display: none;
}

@media (min-width: 1024px) {
	.network-selection {
		display: block;
		max-width: 200px;
		margin: 5px 10px 10px auto;
	}
}

@media (min-width: 1200px) {
	.network-selection {
		margin: 15px 10px 10px auto;
	}
}


@media (max-width: 999px) {
	.filter-selection {
		margin-top: 15px;
	}
}

	.filter-selection select {
		max-width: 300px;
	}
