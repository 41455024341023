.page {
	position: relative;
	overflow-x: hidden;
}


.main-header {
	position: relative;
	margin: 10px;
	padding: 10px 15px;
	background-color: #fff;
}

@media (min-width: 1024px) {
	.main-header {
		display: flex;
		margin: 25px;
	}
}

.main-header .wrapper-t,
.main-header .wrapper-b {
	display: flex;
}

@media (min-width: 1024px) {
	.main-header .wrapper-t {
		width: 80%;
	}

	.main-header .wrapper-b {
		margin-left: auto;
	}
}

@media (min-width: 1200px) {
	.main-header .wrapper-t {
		width: 85%;
	}
}


.main-footer {
	position: relative;
	margin: 0 10px;
	font-size: 14px;
	color: var(--grey-lighter);
	background-color: var(--blue);
}

@media (min-width: 1024px) {
	.main-footer {
		margin: 0 25px;
	}
}


	.footer-sitemap {
		margin-top: 25px;
		padding: 25px 25px 50px;
	}


	.footer-newsletter {
		padding: 0;
	}


	.footer-colophon {
		padding: 10px;
		font-size: 10px;
		color: var(--color1);
		background-color: var(--bg);
	}

	@media (min-width: 1024px) {
		.footer-colophon {
			padding: 25px 20px;
		}
	}

	.footer-colophon .signed {
		position: absolute;
		right: -20px;
		bottom: 0;
	}

		.footer-colophon .signed svg {
			fill: #eef3f7;
		}


	.legal-links {
		margin-top: 5px;
	}

		.legal-links a {
			position: relative;
			display: inline-block;
			padding: 8px 0;
			color: var(--accent);
		}

		.legal-links a:focus::before,
		.legal-links a:hover::before {
			content: "";
			position: absolute;
			top: 75%;
			display: block;
			width: 100%;
			height: 2px;
			background-color: var(--color1);
			transition: var(--transition);
		}

		.legal-links a:not(:last-child)::after {
			content: "/";
			position: absolute;
			top: 25%;
			right: -10px;
			display: inline-block;
			font-size: 12px;
			font-weight: 900;
			margin-left: 10px;
			color: var(--color2);
		}

		.legal-links a + a {
			margin-left: 15px;
		}
